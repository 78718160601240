import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { loadUserProfile, updateUserProfile } from "Core/actions";
import styled, { css } from "styled-components";
import { diff } from "deep-object-diff";
import snakeCase from "lodash/snakeCase";
import sortBy from "lodash/sortBy";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faBell } from "@fortawesome/free-regular-svg-icons";
import {
  faTasks,
  faLock,
  faSignOutAlt,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import Checkbox from "UI/Checkbox";
import Button from "UI/Button";
import UploadImage from "UI/UploadImage";
import Input from "UI/Input";
import { Tabs, Tab, TabContent } from "UI/Tabs";
import TabsSwitcher from "UI/TabsSwitcher";
import { SocialProfiles } from "UI/SocialProfiles";

import CatalogPlaceholder from "../components/Catalog/CatalogPlaceholder";
import ImageModal from "../components/layouts/modals/ImageModal";
import { ContestWorkView } from "../components/ContestWorkView";
import { StudentWork } from "../components/StudentWork";
import CardWork from "../components/CardWork/CardWork";
import {
  CoursesCountIcon,
  WorksCountIcon,
} from "../components/layouts/icons/ProfileIcons";
import Spinner from '../components/layouts/Spinner';
import SocialProfilesInputs from "../components/Profile/SocialProfilesInputs";
import CatalogRoot from './CatalogRoot';

function Profile(props) {
  const {
    id: myId,
    loading,
    avatarUrl,
    fullname = "",
    authSocialLinkShown = false,
    worksCount,
    coursesCount,
    socials = [],
    mySocials = [],
    userNotifications,
    socAuthProvider,
    authProvider,
    linkPhoneToAccNeedsConfirm,
    phoneNumberLinked,
    eduCerts = [],
  } = props;

  const { addToast } = useToasts();
  const [activeTab, setActiveTab] = useState("works");
  const [contestWorkViewId, setContestWorkViewId] = useState(null);
  const [contestTabWasShowed, setContestTabWasShowed] = useState(false);
  const [certificateFullsized, setCertificateFullsized] = useState(null);

  useEffect(() => {
    document.getElementById("main_wrapper").style.background = "#fcfcfc";
    props.loadUserProfile();
  }, []);

  useEffect(() => {
    // рендерим (подгружаем) конкурсные работы, только после перехода
    if (activeTab === 'contest-works' && !contestTabWasShowed) {
      setContestTabWasShowed(true);
    }
  }, [activeTab]);

  const StudentWorkWrapper = workProps => (
    <div className="catalog-item cell cell_33">
      <StudentWork {...workProps} />
    </div>
  );

  const ContestWorkWrapper = workProps => (
    <div className="catalog-item cell cell_33">
      <CardWork
        {...workProps}
        onClick={setContestWorkViewId}
        showPrize
      />
    </div>
  );

  const userNotificationTypes = [
    {
      id: "homework_status_changed",
      label: "Статус домашней работы изменился",
    },
    {
      id: "homework_fame_hall_sent",
      label: 'Работа добавлена в "Работы учеников"',
    },
    { id: "homework_liked", label: "Работе поставили лайк" },
    { id: "homework_commented", label: "Работу прокомментировали" },
  ];

  const socialTemplates = [
    "https://vk.com/",
    "https://facebook.com/",
    "https://behance.net/",
    "https://dribbble.com/",
    "https://youtube.com/",
    "https://artstation.com/",
    "https://instagram.com/",
    "https://dprofile.ru",
  ];

  const renderContent = () => {
    if (loading) {
      return <Spinner className="py-5" />;
    }

    return (
      <Tabs>
        <Tab defaultActive icon={<FontAwesomeIcon icon={faUserCircle} />}>Мой профиль</Tab>
        <Tab icon={<FontAwesomeIcon icon={faTasks} />}>Редактировать профиль</Tab>
        <Tab
          hidden={socAuthProvider && !phoneNumberLinked}
          icon={<FontAwesomeIcon icon={faLock} />}
        >
          Сменить пароль
        </Tab>
        <Tab
          hidden={!socAuthProvider || (socAuthProvider && phoneNumberLinked)}
          icon={<FontAwesomeIcon icon={faMobileAlt} />}
        >
          Привязать телефон
        </Tab>
        <Tab icon={<FontAwesomeIcon icon={faBell} />}>Настроить уведомления</Tab>
        <Tab
          link="/profile/logout"
          icon={<FontAwesomeIcon icon={faSignOutAlt} />}
        >
          Выйти
        </Tab>
        <TabContent contentFor="0">
          <MyProfileSection>
            <UserAvatar image={avatarUrl} />
            <div className="user-info">
              <SectionTitle>{fullname}</SectionTitle>
              <AchievementCounters>
                {coursesCount && (
                  <div className="achievement-counter">
                    <CoursesCountIcon />
                    <span>{coursesCount}</span>
                  </div>
                )}
                {worksCount && (
                  <div className="achievement-counter">
                    <WorksCountIcon />
                    <span>{worksCount}</span>
                  </div>
                )}
              </AchievementCounters>
              {socials && socials.length > 0 && (
                <div className="social-profiles">
                  <SocialProfiles links={socials} />
                </div>
              )}
            </div>
          </MyProfileSection>
        </TabContent>
        <TabContent contentFor="0">
          <MyResultsSection>
            <TabsSwitcher
              defaultActive="works"
              items={{ works: "Работы", certs: "Сертификаты", 'contest-works': 'Конкурсы' }}
              onSwitch={idx => setActiveTab(idx)}
            />
            {/* active tab works */}
            <div
              className={cn("wrapper", {
                shown: activeTab === "works",
              })}
            >
              <CatalogRoot
                options={{
                  header: {
                    categoriesShown: true,
                  },
                }}
                defaultAllFilterName="Все работы"
                entity="homeworks"
                reduxCatalogKey="myProfileWorks"
                ItemComponent={StudentWorkWrapper}
                customParams={{ myProfile: 1 }}
              />
            </div>

            {/* active tab works certs */}
            <div
              className={cn("wrapper", {
                shown: activeTab === "certs",
              })}
            >
              {eduCerts.length ? (
                <>
                  <MyCerts>
                    {eduCerts.map((item, idx) => (
                      <div className="cert" key={idx}>
                        <img
                          src={item.url}
                          onClick={() => setCertificateFullsized(item.url)}
                        />
                      </div>
                    ))}
                  </MyCerts>
                  {certificateFullsized && (
                    <ImageModal
                      image_src={certificateFullsized}
                      closeImageModal={() => setCertificateFullsized(null)}
                    />
                  )}
                </>
              ) : (
                <div className="my-4">
                  <CatalogPlaceholder text="Сертификаты отсутствуют" />
                </div>
              )}
            </div>

            {/* active tab works contest works */}
            <div
              className={cn("wrapper", {
                shown: activeTab === "contest-works",
              })}
            >
              {contestWorkViewId && (
                <ContestWorkView
                  id={contestWorkViewId}
                  reduxCatalogKey="myProfileContestWorks"
                  closeModal={() => setContestWorkViewId(null)}
                />
              )}

              {/* не рендерим каталог, до тех пор, пока не кликнут на вкладку */}
              {contestTabWasShowed && (
                <CatalogRoot
                  entity="contestants"
                  reduxCatalogKey="myProfileContestWorks"
                  ItemComponent={ContestWorkWrapper}
                  customParams={{
                    userId: parseInt(myId, 10),
                    contestsUserPage: 1,
                  }}
                />
              )}
            </div>
          </MyResultsSection>
        </TabContent>
        <TabContent contentFor="1">
          <EditMyProfileSection columnType>
            <SectionTitle>Редактировать профиль</SectionTitle>
            <Formik
              initialValues={{
                avatar: null,
                authSocialLinkShown,
                fullname,
                socials: mySocials,
              }}
              validationSchema={Yup.object({
                fullname: Yup.string()
                  .min(5, "Имя должно быть не менее 5 символов")
                  .required("Имя обязательно для заполнения"),
              })}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setFieldValue }) => {
                const {
                  socials: newSocials,
                  fullname: newFullname,
                  authSocialLinkShown: newAuthSocialLinkShown,
                  avatar,
                } = values;
                let data = {};

                if (Object.keys(diff(mySocials, newSocials)).length) {
                  data = { ...data, socials: [] };

                  for (const social of newSocials) {
                    for (const tmpl of socialTemplates) {
                      const tmplRx = new RegExp(
                        tmpl.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
                      );

                      if (tmplRx.test(social)) {
                        if (tmpl !== social) {
                          data.socials.push(social);
                          break;
                        } else {
                          return addToast(`Дополните ссылку ${social}`, {
                            appearance: "error",
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                          });
                        }
                      }
                    }
                  }
                }

                if (fullname !== newFullname) {
                  data = { ...data, fullname: newFullname };
                }

                if (authSocialLinkShown !== newAuthSocialLinkShown) {
                  data = {
                    ...data,
                    auth_social_link_shown: newAuthSocialLinkShown,
                  };
                }

                if (avatar) {
                  data = { ...data, avatar };
                }

                if (!Object.keys(data).length) return;

                props
                  .updateUserProfile(data)
                  .then(() => {
                    addToast("Данные успешно сохранены", {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 3000,
                    });

                    setFieldValue("avatar", null);
                  })
                  .catch(({ error }) => {
                    addToast(error, {
                      appearance: "error",
                      autoDismiss: true,
                      autoDismissTimeout: 3000,
                    });
                  });
              }}
            >
              {formikProps => (
                <Form
                  onReset={formikProps.handleReset}
                  onSubmit={formikProps.handleSubmit}
                >
                  <UploadImage
                    label="Загрузить фото"
                    name="avatar"
                    disabled={socAuthProvider}
                    onChange={(file) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);

                      reader.onloadend = (e) => {
                        formikProps.setFieldValue("avatar", e.target.result);
                      };
                    }}
                  >
                    <UserAvatar
                      className="user-avatar"
                      middle
                      image={formikProps.values.avatar || avatarUrl}
                    />
                  </UploadImage>
                  <MyPageAddress>
                    Адрес страницы:
                    {" "}
                    <a
                      target="_blank"
                      href={`${window.location.origin}/user/${myId}`}
                    >
                      {`${window.location.origin}/user/${myId}`}
                    </a>
                  </MyPageAddress>
                  <section className="inputs-wrapper">
                    <Input
                      disabled={socAuthProvider}
                      label="Фамилия и имя"
                      name="fullname"
                      placeholder="Введите фамилию и имя"
                    />
                    <LineSplitter />
                    <SocialProfilesInputs authProvider={authProvider} />
                  </section>
                  <Button caption="Сохранить" />
                </Form>
              )}
            </Formik>
          </EditMyProfileSection>
        </TabContent>
        <TabContent contentFor="2">
          <ChangePasswdSection columnType>
            <SectionTitle>Сменить пароль</SectionTitle>
            <Formik
              initialValues={{
                passOld: "",
                passNew: "",
                passNewConfirm: "",
              }}
              validationSchema={Yup.object({
                passOld: Yup.string().required("Укажите старый пароль"),
                passNew: Yup.string()
                  .min(6, "Минимальная длина пароля - 6 символов")
                  .matches(
                    /[a-zA-Z0-9]/,
                    "Пароль может состоять только из символов латиницы и цифр",
                  )
                  .required("Укажите новый пароль"),
                passNewConfirm: Yup.string()
                  .when("passNew", {
                    is: val => (!!(val && val.length > 0)),
                    then: Yup.string().oneOf(
                      [Yup.ref("passNew")],
                      "Новые пароли не совпадают",
                    ),
                  })
                  .required("Укажите повторно новый пароль"),
              })}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { resetForm }) => {
                const password = Object.keys(values).reduce((t, key) => {
                  if (typeof t === "string") {
                    t = {
                      [snakeCase(t)]: values[t],
                    };
                  }

                  t[snakeCase(key)] = values[key];
                  return t;
                });

                props
                  .updateUserProfile({ password })
                  .then((resp) => {
                    resetForm();
                    addToast("Данные успешно сохранены", {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 3000,
                    });
                  })
                  .catch(({ error }) => {
                    addToast(error, {
                      appearance: "error",
                      autoDismiss: true,
                      autoDismissTimeout: 3000,
                    });
                  });
              }}
            >
              {formikProps => (
                <Form
                  onReset={formikProps.handleReset}
                  onSubmit={formikProps.handleSubmit}
                >
                  <section className="d-flex align-items-end flex-column inputs-wrapper">
                    <Input
                      label="Старый пароль"
                      name="passOld"
                      type="password"
                      placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;"
                    />
                    <Input
                      label="Новый пароль"
                      name="passNew"
                      type="password"
                      placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;"
                    />
                    <Input
                      label="Повторите пароль"
                      name="passNewConfirm"
                      type="password"
                      placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;"
                    />
                  </section>
                  <Button caption="Изменить пароль" />
                </Form>
              )}
            </Formik>
          </ChangePasswdSection>
        </TabContent>
        <TabContent contentFor="3">
          <LinkPhoneToAccSection columnType>
            <SectionTitle>Привязка номера телефона</SectionTitle>
            {linkPhoneToAccNeedsConfirm ? (
              <Formik
                initialValues={{
                  smsCodeLinking: "",
                }}
                validationSchema={Yup.object({
                  smsCodeLinking: Yup.string()
                    .matches(
                      /[0-9]/,
                      "SMS-код подтверждения может состоять только из цифр",
                    )
                    .length(
                      5,
                      "Длина SMS-кода подтверждения должна быть равна 5 символам",
                    )
                    .required("Поля SMS-кода подтвеждения обязательно"),
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm }) => {
                  const data = Object.keys(values)
                    .map(key => ({
                      [snakeCase(key)]: values[key],
                    }))
                    .pop();

                  props
                    .updateUserProfile(data)
                    .then((resp) => {
                      resetForm();
                      addToast(
                        "К Вашему аккаунту успешно привязан указанный Вами номер",
                        {
                          appearance: "success",
                          autoDismiss: true,
                          autoDismissTimeout: 3000,
                        },
                      );
                      setTimeout(() => {
                        addToast(
                          "На Ваш номер выслан временный пароль для доступа к аккаунту по номеру телефона. Смените его, если это необходимо",
                          {
                            appearance: "warning",
                            autoDismiss: true,
                            autoDismissTimeout: 10000,
                          },
                        );
                      }, 1000);
                    })
                    .catch(({ error }) => {
                      addToast(error, {
                        appearance: "error",
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                      });
                    });
                }}
              >
                {formikProps => (
                  <Form
                    onReset={formikProps.handleReset}
                    onSubmit={formikProps.handleSubmit}
                  >
                    <Input
                      name="smsCodeLinking"
                      label="SMS-код"
                      placeholder="12345"
                      width="120px"
                      textAlign="center"
                    />
                    <Button caption="Привязать номер" />
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{
                  phoneNumberLinking: "",
                }}
                validationSchema={Yup.object({
                  phoneNumberLinking: Yup.string()
                    .matches(
                      /[0-9]/,
                      "Мобильный номер телефона может состоять только из цифр",
                    )
                    .required("Поля номера мобильного телефона обязательно"),
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm }) => {
                  const data = Object.keys(values)
                    .map(key => ({
                      [snakeCase(key)]: values[key],
                    }))
                    .pop();

                  props
                    .updateUserProfile(data)
                    .then((resp) => {
                      resetForm();
                      addToast(
                        "Вам выслан SMS-код для привязки указанного номера телефона",
                        {
                          appearance: "info",
                          autoDismiss: true,
                          autoDismissTimeout: 3000,
                        },
                      );
                    })
                    .catch(({ error }) => {
                      addToast(error, {
                        appearance: "error",
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                      });
                    });
                }}
              >
                {formikProps => (
                  <Form
                    onReset={formikProps.handleReset}
                    onSubmit={formikProps.handleSubmit}
                  >
                    <Input
                      name="phoneNumberLinking"
                      placeholder="79991234567"
                      width="200px"
                      textAlign="center"
                    />
                    <Button caption="Получить SMS-код" />
                  </Form>
                )}
              </Formik>
            )}
          </LinkPhoneToAccSection>
        </TabContent>

        <TabContent contentFor="4">
          <NotificationsSection columnType>
            <SectionTitle>Настройка уведомлений</SectionTitle>
            <Formik
              initialValues={{
                userNotifications,
              }}
              onSubmit={(values) => {
                const [initialValue, changedValue] = [
                  sortBy(userNotifications),
                  sortBy(values.userNotifications),
                ];

                const isSame = initialValue.length === changedValue.length
                  && initialValue.every(
                    (element, index) => element === changedValue[index],
                  );

                if (isSame) return;

                const data = Object.keys(values)
                  .map(key => ({
                    [snakeCase(key)]: values[key],
                  }))
                  .pop();

                props
                  .updateUserProfile(data)
                  .then((resp) => {
                    addToast("Данные успешно сохранены", {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 3000,
                    });
                  })
                  .catch(({ error }) => {
                    addToast(error, {
                      appearance: "error",
                      autoDismiss: true,
                      autoDismissTimeout: 3000,
                    });
                  });
              }}
            >
              {formikProps => (
                <Form
                  onReset={formikProps.handleReset}
                  onSubmit={formikProps.handleSubmit}
                >
                  <FieldArray name="userNotifications">
                    {({ form, ...arrayHelpers }) => (
                      <div className="d-flex flex-column checkbox-group">
                        {userNotificationTypes.map(type => (
                          <Checkbox
                            key={type.id}
                            name="userNotifications"
                            label={type.label}
                            value={type.id}
                            checked={form.values.userNotifications.includes(
                              type.id,
                            )}
                            onChange={(checked) => {
                              if (checked) {
                                arrayHelpers.push(type.id);
                              } else {
                                const idx = form.values.userNotifications.indexOf(
                                  type.id,
                                );
                                arrayHelpers.remove(idx);
                              }
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <Button caption="Сохранить" />
                </Form>
              )}
            </Formik>
          </NotificationsSection>
        </TabContent>
      </Tabs>
    );
  };

  return (
    <UserProfilePage>
      {renderContent()}
    </UserProfilePage>
  );
}

const mapStateToProps = (state) => {
  const props = state.local.userProfileReducer;
  return props;
};

const ProfileConnected = connect(mapStateToProps, {
  loadUserProfile,
  updateUserProfile: data => dispatch => new Promise((resolve, reject) => dispatch(updateUserProfile({ ...data, resolve, reject })),
  ),
})(Profile);

export default ProfileConnected;
export {
  UserProfilePage,
  UserAvatar,
  SectionTitle,
  AchievementCounters,
  MyCerts,
};

const MyCerts = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;

  .cert {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: content-box;
    width: 283px;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 30px 10px 0;

    img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }
`;

const Form = styled.form.attrs(props => ({
  ...props,
}))`
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;
  justify-content: start;
  align-items: center;

  > .button-component {
    margin-top: 30px;
  }
`;

const UserProfilePage = styled.div`
  position: relative;
  margin: 30px 0 60px;
`;

const Panel = styled.div`
  position: relative;
  display: none;
  flex: 1;
  justify-content: start;
  align-items: center;
  background: #fff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;

  ${props => props.active
    && css`
      display: flex;
    `}

  ${(props) => {
    if (props.columnType) {
      return css`
        flex-flow: column;
        padding: 30px 0;
      `;
    }

    return css`
      flex-flow: row;
    `;
  }}
`;

const MyProfileSection = styled(Panel)`
  position: relative;
  flex: 1;
  padding: 0 50px;

  h5 {
    margin: 0;
  }

  .social-profiles {
    margin: 16px 0 0;
  }

  .user-info {
    position: relative;
    margin-left: 37px;
  }
`;

const MyResultsSection = styled(Panel)`
  flex-flow: column;
  flex: auto;
  width: 100%;
  margin: 20px 0 0;
  padding: 30px 0;

  .wrapper {
    display: none;

    &.shown {
      display: block;
    }
  }
`;

const EditMyProfileSection = styled(Panel)`
  .upload-file-component {
    margin-top: 30px;
  }

  .inputs-wrapper {
    margin-top: 50px;
  }
`;

const ChangePasswdSection = styled(Panel)`
  .inputs-wrapper {
    margin-top: 30px;
  }

  .input-component {
    margin: 9px 0;
  }

  .button-component {
    margin: 30px 0 0;
  }
`;

const LinkPhoneToAccSection = styled(Panel)`
  .input-component {
    margin: 36px 0 15px;
  }
`;

const NotificationsSection = styled(Panel)`
  .checkbox-group {
    margin: 30px 0 0;
  }
`;

const UserAvatar = styled.div`
  position: relative;
  border-radius: 999px;
  width: 154px;
  height: 154px;
  background-color: #f1f1f1;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  ${props => props.middle
    && css`
      width: 148px;
      height: 148px;
    `}

  ${props => /cat_avatar\.png/.test(props.image)
    && css`
      border-radius: 0;
      background-color: transparent;
      background-size: auto;
    `}

  ${props => props.image
    && css`
      background-image: url(${props => props.image});
    `}
`;

const AchievementCounters = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;

  .achievement-counter {
    display: flex;
    align-items: center;
    font: normal 15px/1 Roboto;
    margin-left: 30px;
    margin-top: 16px;

    span {
      margin-left: 12px;
      font-size: 18px;
      color: #555;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`;

const SectionTitle = styled.h5`
  margin: 0;
  font: 22px/1 Roboto;
`;

const MyPageAddress = styled.div`
  margin-top: 50px;
`;

const LineSplitter = styled.hr`
  background: #f2f2f2;
  margin: 34px 0 30px;
  height: 1px;
  border: none;
`;
